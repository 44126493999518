@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext,vietnamese");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext");
.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 2em;
  font-weight: 500;
  color: #333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow: hidden;
  letter-spacing: 0.05em;
  font-size: 16px;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

.inner2 {
  margin: 0 auto;
  max-width: 1160px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding: 0 0 100px 0;
}

@media only screen and (max-width: 1200px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 834px) {
  #contents {
    padding: 0 10px 50px 10px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #17e012;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Sorts Mill Goudy", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@media only screen and (max-width: 834px) {
  header#global_header {
    padding-top: 68px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  position: fixed;
  z-index: 11;
  top: 0;
  width: 100%;
  height: auto !important;
  background: rgba(255, 255, 255, 0.9);
}

#header {
  width: 100%;
  height: 100%;
  border-bottom: 3px solid #229946;
}

#header .inner {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  #header .inner {
    padding: 20px 10px;
  }
}

#header .inner #siteID {
  width: auto;
}

@media only screen and (max-width: 1000px) {
  #header .inner #siteID {
    width: 28%;
  }
}

#header .inner #siteID a {
  display: block;
}

#header .inner #siteID a img {
  max-width: calc(712px / 2);
  width: 100%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .inner #siteID {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 12;
    height: 68px;
  }
  #header .inner #siteID img {
    width: 90%;
  }
  #header .inner #siteID a {
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 640px) {
  #header .inner #siteID {
    width: 50%;
    margin: 0 auto;
  }
  #header .inner #siteID img {
    width: 100%;
  }
  #header .inner #siteID a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
}

#header .inner .hednav {
  width: 68%;
}

@media only screen and (max-width: 834px) {
  #header .inner .hednav {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  #header .inner .hednav {
    position: fixed;
    top: 0;
    right: 0;
    width: 80%;
    z-index: 12;
  }
}

#header .inner .hednav ul {
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .inner .hednav ul {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 640px) {
  #header .inner .hednav ul {
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .inner .hednav ul li {
    width: 48%;
  }
}

@media only screen and (max-width: 640px) {
  #header .inner .hednav ul li {
    width: auto;
  }
}

#header .inner .hednav ul .tel2 {
  margin-right: 30px;
  padding: 15px;
  border: 1px solid #ccc;
  background: #f7f7f7;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 700;
  color: #000;
  letter-spacing: 0.05em;
  font-size: 16px;
  font-size: 16px;
  font-size: 0.83333vw;
}

@media only screen and (min-width: 1750px) {
  #header .inner .hednav ul .tel2 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  #header .inner .hednav ul .tel2 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1000px) {
  #header .inner .hednav ul .tel2 {
    font-size: 14px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .inner .hednav ul .tel2 {
    font-size: 12px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 640px) {
  #header .inner .hednav ul .tel2 {
    font-size: 12px;
    height: 68px;
    margin-right: 0;
    background: #f4f7f6;
    border: 0;
    border-left: 1px solid #E6EAEA;
  }
}

#header .inner .hednav ul .tel2 .add {
  line-height: 1;
  margin-bottom: 7px;
}

#header .inner .hednav ul .tel2 .txt {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  letter-spacing: 0.1em;
  font-size: 37px;
  font-size: 1.92708vw;
}

@media only screen and (min-width: 1750px) {
  #header .inner .hednav ul .tel2 .txt {
    font-size: 37px;
  }
}

@media only screen and (max-width: 1200px) {
  #header .inner .hednav ul .tel2 .txt {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1000px) {
  #header .inner .hednav ul .tel2 .txt {
    font-size: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .inner .hednav ul .tel2 .txt {
    font-size: 28px;
  }
}

@media only screen and (max-width: 640px) {
  #header .inner .hednav ul .tel2 .txt {
    font-size: 25px;
  }
}

#header .inner .hednav ul .tel2 .txt img {
  vertical-align: baseline;
  margin-right: 7px;
}

#header .inner .hednav ul .tel2 a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #000;
}

#header .inner .hednav ul .cont a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3de28;
  color: #fff;
  padding: 10px 20px;
  font-size: 18px;
  font-size: 0.9375vw;
}

@media only screen and (min-width: 1750px) {
  #header .inner .hednav ul .cont a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1200px) {
  #header .inner .hednav ul .cont a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1000px) {
  #header .inner .hednav ul .cont a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 834px) {
  #header .inner .hednav ul .cont a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
  #header .inner .hednav ul .cont a {
    width: 68px;
    height: 68px;
  }
}

#header .inner .hednav ul .cont a:hover {
  opacity: 1 !important;
  background: #f3b428;
}

#header .inner .hednav ul .cont a i {
  margin-right: 7px;
  font-size: 27px;
  font-size: 1.40625vw;
}

@media only screen and (min-width: 1750px) {
  #header .inner .hednav ul .cont a i {
    font-size: 27px;
  }
}

@media only screen and (max-width: 1200px) {
  #header .inner .hednav ul .cont a i {
    font-size: 25px;
  }
}

@media only screen and (max-width: 1000px) {
  #header .inner .hednav ul .cont a i {
    font-size: 20px;
  }
}

@media only screen and (max-width: 834px) {
  #header .inner .hednav ul .cont a i {
    font-size: 27px;
  }
}

@media only screen and (max-width: 640px) {
  #header .inner .hednav ul .cont a i {
    margin-right: 0;
  }
}

@media only screen and (max-width: 640px) {
  #header .inner .hednav ul .cont a span {
    display: none;
  }
}

.navwrap {
  width: 100%;
}

.navwrap #nav_global {
  width: 100%;
  background: #f4f2ee;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 834px) {
  .navwrap #nav_global {
    background: inherit;
    border-bottom: 0;
    box-shadow: none;
  }
}

.navwrap #nav_global ul {
  padding: 10px 0;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  .navwrap #nav_global ul {
    padding: 10px;
  }
}

.navwrap #nav_global ul li {
  border-left: 2px dashed #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-grow: 1;
}

@media only screen and (max-width: 834px) {
  .navwrap #nav_global ul li {
    border-left: 0;
  }
}

.navwrap #nav_global ul li:last-child {
  border-right: 2px dashed #cccccc;
}

@media only screen and (max-width: 834px) {
  .navwrap #nav_global ul li:last-child {
    border-right: 0;
  }
}

.navwrap #nav_global ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-weight: 700;
  line-height: 1.4em;
  color: #111 !important;
  padding: 20px 0;
  font-size: 18px;
  font-size: 0.9375vw;
}

@media only screen and (min-width: 1750px) {
  .navwrap #nav_global ul li a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1200px) {
  .navwrap #nav_global ul li a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1000px) {
  .navwrap #nav_global ul li a {
    font-size: 16px;
  }
}

.navwrap #nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

.navwrap #nav_global ul li a i {
  margin-right: 7px;
  color: #17e012;
  font-size: 20px;
  font-size: 1.04167vw;
}

@media only screen and (min-width: 1750px) {
  .navwrap #nav_global ul li a i {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .navwrap #nav_global ul li a i {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .navwrap #nav_global ul li a i {
    font-size: 18px;
  }
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 834px) {
  .global-menu-btn {
    background: #f4f7f6;
    border-right: 1px solid #E6EAEA;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 68px;
    width: 68px;
    z-index: 11;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: #000;
    line-height: 1;
    transition: 1s;
  }
  .global-menu-btn:hover {
    color: #fff;
    background: #f3de28;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn {
    display: block;
    width: 68px;
    height: 67px;
    background: #f4f7f6;
    border-left: 1px solid #E6EAEA;
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    color: #000;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  #hednav {
    justify-content: center;
    height: 68px;
    width: 100%;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    margin: 0;
    padding: 10px 0;
    background: #eef2f2;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap .navwrap {
    display: block !important;
    background: #eef2f2;
    width: 75%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateX(-240%);
    -ms-transform: translateX(-240%);
    transform: translateX(-240%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  #hednav .wrap .navwrap #hed_info {
    border-bottom: 1px solid #E6EAEA;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #hed_info .store1 {
    border: 1px solid #ccc;
    background: #f7f7f7;
    margin-bottom: 15px;
  }
  #hednav .wrap .navwrap #hed_info .store1 i {
    font-weight: 700;
    margin-left: 7px;
    font-size: 1.5rem !important;
  }
  #hednav .wrap .navwrap #hed_info .store1 a {
    padding: 15px;
    color: #000 !important;
  }
  #hednav .wrap .navwrap #hed_info .store1 .add {
    width: 100%;
    font-size: 12px;
  }
  #hednav .wrap .navwrap #hed_info .store1 .txt {
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #hednav .wrap .navwrap #hed_info .store1 .txt img {
    margin-right: 7px;
  }
  #hednav .wrap .navwrap #hed_info .store2 a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3de28;
    color: #fff;
    padding: 20px;
    font-size: 25px;
  }
  #hednav .wrap .navwrap #hed_info .store2 a:hover {
    opacity: 1 !important;
    background: #f3b428;
  }
  #hednav .wrap .navwrap #hed_info .store2 a i {
    font-size: 30px;
    margin-right: 7px;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn {
    width: 100%;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    transition: 1s;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
  }
  #hednav .wrap .navwrap #nav_global ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global ul li {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #E6EAEA;
  }
  #hednav .wrap .navwrap #nav_global ul li ul {
    display: block !important;
    position: static;
    height: auto !important;
  }
  #hednav .wrap .navwrap #nav_global ul li ul li a {
    color: #fff !important;
  }
  #hednav .wrap .navwrap #nav_global ul li ul li a:before {
    display: none;
  }
  #hednav .wrap .navwrap #nav_global ul li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    line-height: 1;
    height: 100%;
    padding: 24px 36px;
    font-size: 16px;
    font-weight: bold;
    color: #202121 !important;
    cursor: pointer;
  }
  #hednav .wrap .navwrap #nav_global ul li a:hover {
    background: #ccc;
  }
  #hednav .wrap .navwrap #nav_global ul li a span {
    display: none;
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

@keyframes mainscl {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes mainscl2 {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}

#mainArea {
  position: relative;
  width: 100%;
  background: #fff;
}

#mainArea img {
  width: 100% !important;
  height: auto;
  opacity: 0.1 !important;
}

#mainArea .slick-center img {
  opacity: 1 !important;
}

#mainArea ul li {
  position: relative;
}

#mainArea ul li .txt {
  padding: 3% 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  #mainArea ul li .txt {
    padding: 2% 0;
  }
}

@media only screen and (max-width: 834px) {
  #mainArea ul li .txt {
    padding: 3% 0;
  }
}

#mainArea ul li .txt dl {
  color: #fff;
  height: 100%;
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (max-width: 1200px) {
  #mainArea ul li .txt dl {
    width: 60%;
  }
}

@media only screen and (max-width: 1000px) {
  #mainArea ul li .txt dl {
    width: 75%;
  }
}

@media only screen and (max-width: 834px) {
  #mainArea ul li .txt dl {
    width: 85%;
    height: 85%;
  }
}

#mainArea ul .slid01 .txt dl {
  padding: 3%;
  background: rgba(21, 202, 17, 0.8);
  border: 1px solid #e6e0dd;
}

@media only screen and (max-width: 1200px) {
  #mainArea ul .slid01 .txt dl {
    padding: 2%;
  }
}

@media only screen and (max-width: 834px) {
  #mainArea ul .slid01 .txt dl {
    padding: 3%;
  }
}

#mainArea ul .slid01 .txt dl dt {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 35px;
  font-size: 1.82292vw;
}

@media only screen and (min-width: 1750px) {
  #mainArea ul .slid01 .txt dl dt {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1200px) {
  #mainArea ul .slid01 .txt dl dt {
    font-size: 25px;
    margin-bottom: 20px;
  }
}

#mainArea ul .slid01 .txt dl dd a {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px;
  line-height: 1;
  padding: 15px;
  color: #fff;
  background: #f3de28;
  font-size: 20px;
  font-size: 1.04167vw;
}

@media only screen and (min-width: 1750px) {
  #mainArea ul .slid01 .txt dl dd a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  #mainArea ul .slid01 .txt dl dd a {
    font-size: 16px;
    margin: 0 auto 15px;
  }
}

#mainArea ul .slid01 .txt dl dd a:hover {
  opacity: 1 !important;
  background: #f3b428;
}

#mainArea ul .slid01 .txt dl dd a i {
  line-height: 1;
  vertical-align: middle;
  margin-right: 10px;
  font-size: 25px;
  font-size: 1.30208vw;
}

@media only screen and (min-width: 1750px) {
  #mainArea ul .slid01 .txt dl dd a i {
    font-size: 25px;
  }
}

@media only screen and (max-width: 1200px) {
  #mainArea ul .slid01 .txt dl dd a i {
    font-size: 20px;
  }
}

#mainArea ul .slid02 .txt {
  justify-content: flex-start;
  left: 3%;
}

@media only screen and (max-width: 1200px) {
  #mainArea ul .slid02 .txt {
    left: 2%;
  }
}

@media only screen and (max-width: 834px) {
  #mainArea ul .slid02 .txt {
    left: 0;
    justify-content: center;
  }
}

#mainArea ul .slid02 .txt dl {
  padding: 3%;
  background: rgba(11, 180, 243, 0.8);
  border: 1px solid #e6e0dd;
}

@media only screen and (max-width: 1200px) {
  #mainArea ul .slid02 .txt dl {
    padding: 2%;
  }
}

@media only screen and (max-width: 834px) {
  #mainArea ul .slid02 .txt dl {
    padding: 3%;
  }
}

#mainArea ul .slid02 .txt dl dt {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 35px;
  font-size: 1.82292vw;
}

@media only screen and (min-width: 1750px) {
  #mainArea ul .slid02 .txt dl dt {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1200px) {
  #mainArea ul .slid02 .txt dl dt {
    font-size: 25px;
    margin-bottom: 20px;
  }
}

#mainArea ul .slid02 .txt dl dd {
  text-align: left;
  font-size: 20px;
  font-size: 1.04167vw;
}

@media only screen and (min-width: 1750px) {
  #mainArea ul .slid02 .txt dl dd {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  #mainArea ul .slid02 .txt dl dd {
    font-size: 16px;
  }
}

#mainArea ul .slid03 .txt {
  justify-content: flex-end;
  right: 3%;
}

@media only screen and (max-width: 1200px) {
  #mainArea ul .slid03 .txt {
    right: 2%;
  }
}

@media only screen and (max-width: 834px) {
  #mainArea ul .slid03 .txt {
    justify-content: center;
    right: auto;
  }
}

#mainArea ul .slid03 .txt dl {
  padding: 3%;
  background: rgba(243, 222, 40, 0.8);
  border: 1px solid #e6e0dd;
}

@media only screen and (max-width: 1200px) {
  #mainArea ul .slid03 .txt dl {
    padding: 2%;
  }
}

@media only screen and (max-width: 834px) {
  #mainArea ul .slid03 .txt dl {
    padding: 3%;
  }
}

#mainArea ul .slid03 .txt dl dt {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 35px;
  font-size: 1.82292vw;
}

@media only screen and (min-width: 1750px) {
  #mainArea ul .slid03 .txt dl dt {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1200px) {
  #mainArea ul .slid03 .txt dl dt {
    font-size: 25px;
    margin-bottom: 20px;
  }
}

#mainArea ul .slid03 .txt dl dd {
  text-align: center;
  font-size: 20px;
  font-size: 1.04167vw;
}

@media only screen and (min-width: 1750px) {
  #mainArea ul .slid03 .txt dl dd {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  #mainArea ul .slid03 .txt dl dd {
    font-size: 16px;
  }
}

#local-keyvisual {
  position: relative;
  background-image: url(../images/common/main_1.jpg);
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

#local-keyvisual h1 {
  display: inline-block;
  z-index: 1;
  position: relative;
  color: #fff;
  text-align: center;
  text-shadow: 0px 3px 9.7px rgba(0, 0, 0, 0.35);
  font-weight: 600;
  padding: 240px 0;
  line-height: 1.7em;
  font-size: 50px;
  font-size: 2.60417vw;
}

@media only screen and (min-width: 1600px) {
  #local-keyvisual h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual h1 {
    font-size: 40px;
    padding: 100px 10px;
  }
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 30px;
  }
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
}

.pan1 {
  margin: 50px 0;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #333;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  color: #000;
}

footer#global_footer a {
  color: #000;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

#footer2 {
  color: #fff;
  background: url(../images/common/cont_bg.jpg) center top no-repeat;
  background-size: cover;
  padding: 100px 10px;
}

@media only screen and (max-width: 1200px) {
  #footer2 {
    padding: 50px 10px;
  }
}

#footer2 .inner2 p {
  margin-bottom: 30px;
  width: 100%;
  font-size: 20px;
  font-size: 1.04167vw;
}

@media only screen and (min-width: 1600px) {
  #footer2 .inner2 p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  #footer2 .inner2 p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 834px) {
  #footer2 .inner2 p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

#footer2 .inner2 .ttl {
  font-size: 37px;
  font-size: 1.92708vw;
}

@media only screen and (min-width: 1600px) {
  #footer2 .inner2 .ttl {
    font-size: 37px;
  }
}

@media only screen and (max-width: 1000px) {
  #footer2 .inner2 .ttl {
    font-size: 30px;
  }
}

@media only screen and (max-width: 834px) {
  #footer2 .inner2 .ttl {
    font-size: 25px;
  }
}

#footer2 .inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#footer2 .inner dl {
  width: 48%;
  background: rgba(255, 255, 255, 0.5);
  padding: 50px 0;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #footer2 .inner dl {
    width: 49%;
  }
}

@media only screen and (max-width: 640px) {
  #footer2 .inner dl {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  #footer2 .inner dl:nth-child(2) {
    margin-top: 50px;
  }
}

#footer2 .inner dl:nth-child(2) dt {
  background: #f3dd28;
}

#footer2 .inner dl dt {
  background: #17e012;
  margin-bottom: 40px;
  line-height: 1.7em;
  display: block;
  width: 100%;
  font-weight: 700;
  color: #fff;
  padding: 20px 10px;
  font-size: 25px;
  font-size: 1.30208vw;
}

@media only screen and (min-width: 1600px) {
  #footer2 .inner dl dt {
    font-size: 25px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #footer2 .inner dl dt {
    font-size: 16px;
  }
}

@media only screen and (max-width: 640px) {
  #footer2 .inner dl dt {
    font-size: 20px;
  }
}

#footer2 .inner dl dd {
  width: 93%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

#footer2 .inner dl dd a {
  width: 100%;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}

#copy {
  padding: 20px 0;
  text-align: center;
  font-size: 13px;
  display: block;
  line-height: 1.7em;
}

@media only screen and (max-width: 640px) {
  #copy {
    text-align: center;
  }
}

#copy a {
  font-size: 13px;
  color: #000;
  text-decoration: none;
}

#copy a:hover {
  opacity: 0.6;
}

@-webkit-keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 12;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

@media only screen and (max-width: 640px) {
  .progress-wrap {
    right: 10px;
    bottom: 140px;
  }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap::before {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  opacity: 0;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap svg {
  z-index: 1;
  position: relative;
  box-shadow: 0px 3px 10px rgba(51, 51, 51, 0.1);
  border-radius: 50%;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #f3dd28;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

body.light .progress-wrap {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
}

body.light .progress-wrap::after {
  color: #f3dd28;
}

body.light .progress-wrap svg.progress-circle path {
  stroke: #f3dd28;
}

.switch,
.circle {
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switch {
  width: 80px;
  height: 4px;
  border-radius: 27px;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  position: fixed;
  right: 50px;
  top: 75px;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  z-index: 33333;
  transition: all 300ms linear;
}

.circle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4b4d64;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  animation: border-transform 10s linear infinite alternate forwards;
}

.circle:before {
  position: absolute;
  font-family: 'unicons';
  content: '\eac1';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  opacity: 1;
  color: #ff8695;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.circle:after {
  position: absolute;
  font-family: 'unicons';
  content: '\eb8f';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: #f8ab37;
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switched .circle {
  left: 45px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #1f2029;
}

.switched .circle:before {
  opacity: 0;
}

.switched .circle:after {
  opacity: 1;
}

.btn a {
  display: inline-block;
  background: #f3de28;
  padding: 15px 20px;
  color: #fff;
}

.btn a i {
  margin-left: 10px;
}

.cont_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
}

@media only screen and (max-width: 1200px) {
  .cont_1 {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1 {
    flex-wrap: wrap;
  }
}

.cont_1:nth-child(even) {
  flex-direction: row-reverse;
}

.cont_1 .flxL {
  width: 55%;
}

@media only screen and (max-width: 1000px) {
  .cont_1 .flxL {
    width: 50%;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.cont_1 .flxR {
  width: 40%;
}

@media only screen and (max-width: 1000px) {
  .cont_1 .flxR {
    width: 45%;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1 .flxR {
    width: 100%;
  }
}

.cont_1 .flxR img {
  width: 100% !important;
}

@media only screen and (max-width: 834px) {
  .cont_1 .flxR img {
    max-height: 400px;
    object-fit: cover;
    font-family: "object-fit:cover;";
    height: 50vw !important;
  }
}

.cont_1 .flxR .img {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  flex-direction: row !important;
}

.cont_1 .flxR .img .box {
  width: 48%;
}

.cont_1 .flxR .img .box img {
  max-height: 400px;
  height: 20vw !important;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

@media only screen and (max-width: 1400px) {
  .cont_1 .flxR .img .box img {
    height: 30vw !important;
  }
}

@media only screen and (max-width: 1000px) {
  .cont_1 .flxR .img .box img {
    height: 40vw !important;
  }
}

.cont_2 {
  background: #f7f3eb;
}

.cont_2 .inner {
  padding: 80px 0;
}

@media only screen and (max-width: 1200px) {
  .cont_2 .inner {
    padding: 80px 10px;
  }
}

.cont_2 .inner ul li {
  position: relative;
}

.cont_2 .inner ul li:before {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  font-family: "Font Awesome 5 pro";
  content: "\f002";
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  background: #fff;
  color: #000;
  right: 10px;
  bottom: 10px;
}

.cont_3 {
  max-width: 1200px;
  margin: 80px auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .cont_3 {
    padding: 0 10px;
  }
}

.cont_3 .midashi3 {
  width: 100%;
}

.cont_3 .box {
  width: 32%;
  padding: 20px;
  border: 1px solid #ccc;
  background: #f7f7f7;
}

@media only screen and (max-width: 1200px) {
  .cont_3 .box {
    padding: 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_3 .box {
    width: 48%;
  }
}

@media only screen and (max-width: 834px) {
  .cont_3 .box:last-child {
    width: 100%;
    margin-top: 30px;
  }
  .cont_3 .box:last-child img {
    width: 100% !important;
    max-height: 400px;
    object-fit: cover;
    object-position: 100% 100%;
    font-family: "object-fit:cover;";
    height: 40vw !important;
  }
}

.cont_3 .box td {
  display: block !important;
  padding: 0 !important;
  border: 0 !important;
}

.cont_3 .box .img {
  margin-bottom: 30px;
}

.cont_3 .box .ttl {
  font-size: 20px;
  font-weight: 700;
  color: #333;
  margin-bottom: 15px;
}

.cont_3 .box .txt {
  padding: 20px !important;
  background: #fff;
  margin-bottom: 15px;
}

.cont_3 .box .btn {
  text-align: center !important;
}

.cont_3 .box .btn a {
  display: block !important;
}

.cont_3 .box .cl01 a {
  background: #17e012 !important;
}

.cont_3 .box .cl02 a {
  background: #0bb4f3 !important;
}

.cont_3 .box .cl03 a {
  background: #f3dd28 !important;
}

.cont_4 {
  max-width: 1200px;
  margin: 80px auto;
}

@media only screen and (max-width: 1200px) {
  .cont_4 {
    padding: 0 10px;
  }
}

.cont_4 .news {
  z-index: 1;
  position: relative;
  height: 400px;
  overflow-y: auto;
  padding-right: 10px;
}

@media only screen and (max-width: 834px) {
  .cont_4 .news {
    height: 400px;
  }
}

.cont_4 .news dl {
  padding: 15px 0;
  border-bottom: 1px dashed #333;
  display: block !important;
}

.cont_4 .news dl dt, .cont_4 .news dl dd {
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

/* title */
h2.mail.title {
  display: none !important;
}

.midashi {
  display: inline-block;
}

.midashi h2 {
  padding-bottom: 15px;
  font-weight: 700;
  line-height: 1.4em;
  color: #222;
  background: url(../images/common/pt_1.png) repeat-x left bottom;
  font-size: 37px;
  font-size: 1.92708vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h2 {
    font-size: 37px;
  }
}

@media only screen and (max-width: 1000px) {
  .midashi h2 {
    font-size: 25px;
  }
}

.midashi h3 {
  padding-bottom: 15px;
  font-weight: 700;
  line-height: 1.4em;
  color: #222;
  background: url(../images/common/pt_1.png) repeat-x left bottom;
  font-size: 37px;
  font-size: 1.92708vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h3 {
    font-size: 37px;
  }
}

@media only screen and (max-width: 1000px) {
  .midashi h3 {
    font-size: 25px;
  }
}

.midashi2 {
  display: inline-block;
}

.midashi2 h2 {
  padding-bottom: 15px;
  font-weight: 700;
  line-height: 1.4em;
  color: #222;
  background: url(../images/common/pt_2.png) repeat-x left bottom;
  font-size: 37px;
  font-size: 1.92708vw;
}

@media only screen and (min-width: 1600px) {
  .midashi2 h2 {
    font-size: 37px;
  }
}

@media only screen and (max-width: 1000px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

.midashi2 h3 {
  padding-bottom: 15px;
  font-weight: 700;
  line-height: 1.4em;
  color: #222;
  background: url(../images/common/pt_2.png) repeat-x left bottom;
  font-size: 37px;
  font-size: 1.92708vw;
}

@media only screen and (min-width: 1600px) {
  .midashi2 h3 {
    font-size: 37px;
  }
}

@media only screen and (max-width: 1000px) {
  .midashi2 h3 {
    font-size: 25px;
  }
}

.midashi3 h2 {
  color: #222;
  font-weight: 700;
  font-size: 37px;
  font-size: 1.92708vw;
}

@media only screen and (min-width: 1600px) {
  .midashi3 h2 {
    font-size: 37px;
  }
}

@media only screen and (max-width: 1000px) {
  .midashi3 h2 {
    font-size: 25px;
  }
}

.midashi3 h3 {
  color: #222;
  font-weight: 700;
  font-size: 37px;
  font-size: 1.92708vw;
}

@media only screen and (min-width: 1600px) {
  .midashi3 h3 {
    font-size: 37px;
  }
}

@media only screen and (max-width: 1000px) {
  .midashi3 h3 {
    font-size: 25px;
  }
}

.midashi4 h2 {
  background: #229946;
  padding: 10px;
  color: #fff;
  font-weight: 700;
  line-height: 1.7em;
  font-size: 37px;
  font-size: 1.92708vw;
}

@media only screen and (min-width: 1600px) {
  .midashi4 h2 {
    font-size: 37px;
  }
}

@media only screen and (max-width: 1000px) {
  .midashi4 h2 {
    font-size: 25px;
  }
}

.midashi5 h2 {
  padding-bottom: 15px;
  font-weight: 700;
  line-height: 1.4em;
  color: #222;
  background: url(../images/common/pt_1.png) repeat-x left bottom;
  font-size: 30px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .midashi5 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .midashi5 h2 {
    font-size: 25px;
  }
}

.midashi5 h3 {
  padding-bottom: 15px;
  font-weight: 700;
  line-height: 1.4em;
  color: #222;
  background: url(../images/common/pt_1.png) repeat-x left bottom;
  font-size: 25px;
  font-size: 1.30208vw;
}

@media only screen and (min-width: 1600px) {
  .midashi5 h3 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 1000px) {
  .midashi5 h3 {
    font-size: 20px;
  }
}

.midashi6 h2 {
  padding-bottom: 15px;
  font-weight: 700;
  line-height: 1.4em;
  color: #222;
  background: url(../images/common/pt_2.png) repeat-x left bottom;
  font-size: 30px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .midashi6 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .midashi6 h2 {
    font-size: 25px;
  }
}

.midashi6 h3 {
  padding-bottom: 15px;
  font-weight: 700;
  line-height: 1.4em;
  color: #222;
  background: url(../images/common/pt_2.png) repeat-x left bottom;
  font-size: 25px;
  font-size: 1.30208vw;
}

@media only screen and (min-width: 1600px) {
  .midashi6 h3 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 1000px) {
  .midashi6 h3 {
    font-size: 20px;
  }
}

.ext_01 {
  justify-content: space-between !important;
  flex-wrap: wrap;
}

.ext_01 .box {
  margin-bottom: 20px;
  width: 48%;
  text-align: center;
}

.ext_01 .box img {
  width: 100% !important;
}

.ext_01 .box > div {
  margin-top: 15px;
}

.ext_02 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
}

@media only screen and (max-width: 834px) {
  .ext_02 {
    flex-wrap: wrap;
  }
}

.ext_02:nth-child(even) {
  flex-direction: row-reverse;
}

.ext_02 .flxL {
  width: 55%;
}

@media only screen and (max-width: 1000px) {
  .ext_02 .flxL {
    width: 50%;
  }
}

@media only screen and (max-width: 834px) {
  .ext_02 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.ext_02 .flxR {
  width: 40%;
}

@media only screen and (max-width: 1000px) {
  .ext_02 .flxR {
    width: 45%;
  }
}

@media only screen and (max-width: 834px) {
  .ext_02 .flxR {
    width: 100%;
  }
}

.ext_02 .flxR img {
  width: 100% !important;
}

@media only screen and (max-width: 834px) {
  .ext_02 .flxR img {
    max-height: 400px;
    object-fit: cover;
    font-family: "object-fit:cover;";
    height: 50vw !important;
  }
}

.ext_02 .flxR .img {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  flex-direction: row !important;
}

.ext_02 .flxR .img .box {
  width: 48%;
}

.ext_02 .flxR .img .box img {
  max-height: 400px;
  height: 20vw !important;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

@media only screen and (max-width: 1400px) {
  .ext_02 .flxR .img .box img {
    height: 30vw !important;
  }
}

@media only screen and (max-width: 1000px) {
  .ext_02 .flxR .img .box img {
    height: 40vw !important;
  }
}

@media only screen and (max-width: 640px) {
  .comp2 dl {
    text-align: center;
    display: block !important;
  }
}

.comp2 dl dt {
  padding: 15px !important;
}

@media only screen and (max-width: 640px) {
  .comp2 dl dt {
    display: block !important;
    text-align: center;
    width: 100% !important;
  }
}

.comp2 dl dd {
  padding: 15px !important;
}

@media only screen and (max-width: 640px) {
  .comp2 dl dd {
    width: 100% !important;
  }
}

.comp2 dl a {
  color: #000;
}

.waku {
  padding: 40px;
  background: #fff;
  border: double 7px #229946;
}

@media only screen and (max-width: 640px) {
  .waku {
    padding: 20px;
  }
}

.waku2 {
  padding: 20px;
  border: 1px solid #ccc;
  background: #f7f7f7;
}

.waku2 .midashi5 h2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.waku2 .midashi5 span {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #17e012;
  color: #fff;
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.voice ul li {
  margin-bottom: 50px;
}

.voice ul li h2 {
  margin-bottom: 30px;
}

.voice ul li h2 a {
  padding-bottom: 10px;
  border-bottom: 3px dotted #025b31;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #025b31;
  font-weight: 700;
  line-height: 1.7em;
  font-size: 20px;
  font-size: 1.04167vw;
}

@media only screen and (min-width: 1600px) {
  .voice ul li h2 a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .voice ul li h2 a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 834px) {
  .voice ul li h2 a {
    font-size: 18px;
  }
}

.voice ul li .box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.voice ul li .box .left {
  width: 30%;
}

.voice ul li .box .left .blog_photo {
  overflow: hidden;
  height: 300px !important;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

.voice ul li .box .right {
  width: 68%;
}

.voice ul li .box .right ul {
  display: none;
}

.voice ul li .box .right .blog_date {
  text-align: right;
}

.voice ul li .box .right .detail {
  margin-top: 15px;
}

.voice ul li .box .right .detail br {
  display: none;
}

.voice ul li .box .right .btn {
  margin-top: 30px;
  text-align: right;
}

.voice ul li .box .right .btn a {
  display: inline-block;
  padding: 5px 15px;
  background: #44b771;
  color: #fff;
  border-radius: 5px;
}

.samp {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .samp {
    display: block;
  }
}

.samp .flxL {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(../images/common/pt_2.png) no-repeat;
  background-size: cover;
  width: 50%;
  padding: 7%;
}

@media only screen and (max-width: 640px) {
  .samp .flxL {
    width: 100%;
    display: block;
  }
}

.samp .flxL > div {
  width: 100%;
}

.samp .flxL .txt {
  font-family: "Sorts Mill Goudy", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #fff;
}

.samp .flxR {
  width: 50%;
}

@media only screen and (max-width: 640px) {
  .samp .flxR {
    width: 100%;
  }
}

.samp .flxR .img {
  height: 100%;
  position: relative;
}

.samp .flxR .img:before {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
}

@media only screen and (max-width: 640px) {
  .samp .flxR .img:before {
    padding-top: 60%;
  }
}

.samp .flxR .img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

.samp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .samp {
    flex-direction: column-reverse;
  }
}

.samp:nth-child(odd) {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .samp:nth-child(odd) {
    flex-direction: column-reverse;
  }
}

.samp:nth-child(odd) .flxR {
  width: 57%;
  margin-left: 0;
}

@media only screen and (max-width: 640px) {
  .samp:nth-child(odd) .flxR {
    width: 100%;
    margin-right: 0;
  }
}

.samp .flxL {
  width: 40%;
}

@media only screen and (max-width: 640px) {
  .samp .flxL {
    width: 100%;
  }
}

.samp .flxR {
  width: 57%;
}

@media only screen and (max-width: 640px) {
  .samp .flxR {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 0;
  }
}

.samp img {
  width: 100% !important;
}

.samp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .samp {
    flex-direction: column-reverse;
  }
}

.samp:nth-child(odd) {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .samp:nth-child(odd) {
    flex-direction: column;
  }
}

.samp:nth-child(odd) .flxR {
  width: 57%;
  margin-left: 0;
}

@media only screen and (max-width: 834px) {
  .samp:nth-child(odd) .flxR {
    width: 100%;
    margin-right: 0;
  }
}

.samp .flxL {
  width: 40%;
}

@media only screen and (max-width: 834px) {
  .samp .flxL {
    width: 100%;
  }
}

.samp .flxR {
  width: 57%;
}

@media only screen and (max-width: 834px) {
  .samp .flxR {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 0;
  }
}

.samp img {
  width: 100% !important;
}

.kokusai01 {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
  margin-bottom: 50px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .kokusai01 {
    margin-left: -2.43902%;
  }
}

@media only screen and (max-width: 640px) {
  .kokusai01 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.kokusai01 .box {
  position: relative;
  width: 30.89431%;
  height: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .kokusai01 .box {
    width: 30.89431%;
    margin-left: 2.43902%;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .kokusai01 .box {
    width: 48%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 640px) {
  .kokusai01 .box:nth-child(3) {
    width: 100%;
  }
}

.kokusai01 .box img {
  width: 100% !important;
}

.kokusai01 .box > div {
  z-index: 1;
  font-size: 25px;
  font-weight: 600;
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.kokusai02 {
  justify-content: space-between !important;
  flex-wrap: wrap;
}

.kokusai02 .box {
  margin-bottom: 20px;
  width: 31%;
}

.kokusai02 .box article {
  height: 100%;
  display: flex;
  justify-content: flex-start;
}

.kokusai02 .box article h3 {
  min-width: 35px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  vertical-align: middle;
  background-color: #DBDBDB;
  color: #333333;
}

.kokusai03 {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -1.69492%;
  margin-bottom: 50px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .kokusai03 {
    margin-left: -2.1097%;
  }
}

@media only screen and (max-width: 640px) {
  .kokusai03 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.kokusai03 .box {
  position: relative;
  width: 18.30508%;
  height: 18.30508%;
  margin-left: 1.69492%;
  margin-bottom: 30px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .kokusai03 .box {
    width: 31.2236%;
    margin-left: 2.1097%;
    display: block;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .kokusai03 .box {
    width: 48%;
    margin-left: 0;
  }
}

.kokusai03 .box img {
  width: 100% !important;
}

.kokusai03 .box article {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.kokusai03 .box article h3 {
  min-width: 35px;
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  vertical-align: middle;
  background-color: #DBDBDB;
  color: #333333;
}

.member01 {
  padding: 5%;
  background: #f7f7f7;
  border: 1px solid #ccc;
}

.qa dl {
  margin-bottom: 30px;
  display: block !important;
}

.qa dl dt, .qa dl dd {
  border: 0 !important;
  background: inherit !important;
}

.qa dl dt {
  background: #1a8845 !important;
  border: 2px solid #86b241 !important;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
  line-height: 1.7em;
  font-size: 18px;
  font-size: 0.9375vw;
}

@media only screen and (min-width: 1600px) {
  .qa dl dt {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1000px) {
  .qa dl dt {
    font-size: 18px;
  }
}

@media only screen and (max-width: 834px) {
  .qa dl dt {
    font-size: 18px;
  }
}

.faq_q {
  border: none !important;
}

.faq_q > div {
  font-size: 16px;
  border-bottom: 1px #1b4996 dashed;
  padding-bottom: 20px !important;
  position: relative;
  color: #1b4996;
  font-weight: bold;
  position: relative;
  padding-left: 3em !important;
}

.faq_q > div:before {
  content: "Q";
  display: block;
  background: #1b4996;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -3px;
}

.faq_a {
  border: none !important;
}

.faq_a > div {
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  border-bottom: 1px #376eca solid;
  padding-bottom: 30px !important;
  position: relative;
  padding-left: 3em !important;
}

.faq_a > div:before {
  content: "A";
  display: block;
  background: #376eca;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -6px;
}

.contactwrap {
  background: #f7f7f7;
  padding: 50px;
}

.policy a {
  color: #333;
}

.img100 img {
  max-width: 100% !important;
  width: 100% !important;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 834px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}
